document.addEventListener('DOMContentLoaded', () => {
  const hero = document.querySelector('[data-hero]');
  const slidesWrapper = document.querySelectorAll('[data-slides]');

  if (!hero || !slidesWrapper) {
    return
  }
  const slideshow = document.querySelector('[data-slideshow]');
  const placeholder = document.querySelector('[data-placeholder]');
  const controls = document.querySelectorAll('[data-control]');
  placeholder.remove();
  slidesWrapper.forEach(wrapper => {
    const slides = Array.from(wrapper.querySelectorAll('[data-slide]'));
    const renderedSlides = slides.map(slide => slide.querySelector('[data-template]').content.firstElementChild.cloneNode(true));

    slideshow.append(...renderedSlides);

    const observer = new IntersectionObserver(
      (entries) => {
          entries.forEach((entry) => {
            const videoTemplate = entry.target.querySelector('[data-embed]');
            const embedTarget  = entry.target.querySelector('[data-embed-target]');
            if(videoTemplate) {
              console.log(entry, entry.target)
              if (entry.isIntersecting) {
                embedTarget.classList.add('bg-black');
                embedTarget.append(videoTemplate.content.firstElementChild.cloneNode(true))
              } else {
                embedTarget.classList.remove('bg-black');
                embedTarget.innerHTML = ''
              }
            }
          });
      },
      {
          rootMargin: '-1px',
      }
  );
  Array.from(renderedSlides).forEach((item) => {
      observer.observe(item);
  });

  controls.forEach(control => {
    control.addEventListener('click', e => {
      e.preventDefault()
      slideshow.scrollBy({
        top: 0,
        left: control.value * hero.clientWidth,
        behavior: 'smooth',
    });
    })
  })

    wrapper.addEventListener('click', (e) => {
      const link = e.target.closest('[data-slide]');
      if (!link) {
        return
      }
      e.preventDefault();
      const index = [...wrapper.children].indexOf(link)
      slideshow.children[index].scrollIntoView(
        {
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        }
      )
    })
  })
})